export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "eu-west-1",
    BUCKET: "andy-notesapp"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://y17enchu91.execute-api.eu-west-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_idxlfjhJD",
    APP_CLIENT_ID: "2hj7hs0kr18qi827var55hqoe4",
    IDENTITY_POOL_ID: "eu-west-1:b0587fae-d9ac-41a1-bee1-3c50ab177203"
  }
};